import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import {
  footer,
  about_company,
  links,
  linkbutton,
  copyright,
} from "./footer.module.scss";
import { Modal, Button } from "react-bootstrap";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const Footer = () => {
  const [showImpressum, setShowImpressum] = useState(false);
  const [showDatenschutz, setShowDatenschutz] = useState(false);

  //const handleClose = () => setShow(false);
  const handleShowImpressum = () => setShowImpressum(true);
  const handleShowDatenschutz = () => setShowDatenschutz(true);

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
        }
      }
      impressum: contentfulCard(contentfulid: { eq: "impressum" }) {
        header
        body {
          raw
        }
      }
      datenschutz: contentfulCard(contentfulid: { eq: "datenschutz" }) {
        header
        body {
          raw
        }
      }
    }
  `);

  return (
    <footer className="pt-4  pt-md-5 border-top">
      <div className={`mt-5 pt-5 pb-5 ${footer}`}>
        <div className="container">
          <div className="row">
            <div className={`col-lg-5 col-xs-12 ${about_company}`}>
              <h2>Lüftenegger IT-Solutions</h2>
              <p className="pr-5 text-white-50">
                Seit 2016 beraten wir Unternehmen und Privatkunden in
                IT-Angelegenheiten und kümmern uns um individuelle
                Problemlösungen.
              </p>
              <p>
                {/*    <a href="#">
                  <i class="fa fa-facebook-square mr-1"></i>
                </a>
                <a href="#">
                  <i class="fa fa-linkedin-square"></i>
                </a> */}
              </p>
            </div>
            <div className={`col-lg-3 col-xs-12 ${links}`}>
              {/*  <h4 className="mt-lg-0 mt-sm-3">News</h4>
              <ul className="m-0 p-0">
               
              </ul> */}
            </div>
            <div className={`col-lg-4 col-xs-12 ${links}`}>
              <h4 className="mt-lg-0 mt-sm-4">Quick Links</h4>

              <ul className="m-0 p-0">
                {/*      <li>
                  - <a href="#">Blog</a>
                </li> */}
                <li>
                  <Button
                    className={linkbutton}
                    variant="link"
                    onClick={handleShowImpressum}
                  >
                    - Impressum
                  </Button>

                  <Modal
                    size="lg"
                    show={showImpressum}
                    onHide={() => setShowImpressum(false)}
                    dialogClassName=""
                    aria-labelledby="example-custom-modal-styling-title"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="example-custom-modal-styling-title">
                        {data.impressum.header}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>{renderRichText(data.impressum.body)}</p>
                    </Modal.Body>
                  </Modal>
                </li>
                <li>
                  <Button
                    className={linkbutton}
                    variant="link"
                    onClick={handleShowDatenschutz}
                  >
                    - Datenschutz
                  </Button>

                  <Modal
                    size="lg"
                    show={showDatenschutz}
                    onHide={() => setShowDatenschutz(false)}
                    dialogClassName=""
                    aria-labelledby="example-custom-modal-styling-title"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="example-custom-modal-styling-title">
                        {data.datenschutz.header}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>{renderRichText(data.datenschutz.body)}</p>
                    </Modal.Body>
                  </Modal>
                </li>
              </ul>
            </div>
          </div>
          <div className="row mt-5">
            <div className={`col ${copyright}`}>
              <p className="">
                <small className="text-white-50">
                  ©{new Date().getFullYear()}. All Rights Reserved.
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
