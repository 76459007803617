import React from "react"

// import Header from "./header"
// import Navbar from "./navbar"
import Footer from "./footer"
// import Banner from "./banner"

import "bootstrap/dist/css/bootstrap.min.css"
import "../styles/index.scss"

import {container} from "./layout.module.scss"

import { Container } from "react-bootstrap"

const Layout = (props) => {
  return (
    <div>
      <Container className={container}>{props.children}</Container>
      <Footer />
    </div>
  )
}

export default Layout
